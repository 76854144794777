/* eslint-disable no-lonely-if */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGED_IN } from 'pages/auth/constants';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { setOffline } from 'reducers/offlineSlice';
import { logout, resetAccessToken, selectRefreshToken, selectUserDetails, selectUserLoginStatus } from 'pages/auth/authSlice';
import http from 'utils/http';
import { detectDeviceAndOS } from 'utils';
import { registerDevice, getDeviceSettings, setDevice } from 'reducers/pushSlice';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { messaging } from './firebase';
import { activateDevice } from './pages/settings/api';

const Index = ({ children }) => {
  const [url, setUrl] = useState(null);
  const dispatch = useDispatch();
  const refreshToken = useSelector(selectRefreshToken);
  const userDetails = useSelector(selectUserDetails);
  const userLoginStatus = useSelector(selectUserLoginStatus);

  // this function handles fcm token retreival and updates them to backend.
  const getFcmToken = useCallback((registrationfcm) => {
    messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
      serviceWorkerRegistration: registrationfcm
    }).then(async (token) => {
      const deviceInfo = detectDeviceAndOS();
      dispatch(registerDevice({
        token,
        device_info: {
          platform: deviceInfo.deviceType,
          os: deviceInfo.os
        },
        userId: userDetails.userId
      }));
    }).catch((err) => {
      console.error('Token Error', err);
    });
  }, [userDetails.userId]);

  // this function handles service worker for fcm and checks if its scope exists and calls getFCMToken according to it
  const getToken = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        let includeFCMScope = false;
        let regObject = null;
        registrations.forEach((registration) => {
          if (registration.scope.includes('firebase-cloud-messaging-push-scope')) {
            includeFCMScope = true;
            regObject = registration;
          }
        });
        if (!includeFCMScope) {
          navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' })
            .then((registrationfcm) => {
              registrationfcm.addEventListener('updatefound', () => {
                // A new service worker has been found
                registrationfcm.installing.addEventListener('statechange', function() {
                  // Check if the new service worker has become activated
                  if (this.state === 'activated') {
                    if ('serviceWorker' in navigator) {
                      navigator.serviceWorker.getRegistration()
                        .then((registration) => {
                          if (registration && messaging) {
                            getFcmToken(registrationfcm);
                          } else {
                            console.log('No service worker is currently registered.');
                          }
                        })
                        .catch((error) => {
                          console.error('Error getting service worker registration:', error);
                        });
                    } else {
                      console.log('Service workers are not supported in this browser.');
                    }
                    console.log('New service worker activated:', registrationfcm);
                  }
                });
              });
            });
        } else {
          getFcmToken(regObject);
        }
      });
    } else {
      console.log('Browser does not support service worker');
    }
  }, [getFcmToken]);

  // this function handles the notification when the app is in foreground
  useEffect(() => {
    if ('Notification' in window) {
      messaging.onMessage((payload) => {
        const notification = new Notification(payload?.notification?.title, {
          body: payload?.notification?.body,
          tag: payload?.messageId,
          icon: payload?.notification?.icon,
          image: payload?.notification?.image
        });
        // navigate to a URL
        notification.addEventListener('click', () => {
          window.open(payload?.fcmOptions?.link, '_blank');
        });
      });
    }
  }, []);
  useEffect(() => {
    if (document.referrer.includes('cognicept.systems') && window.location.origin.includes('kabam.ai')) {
      setUrl(document.referrer);
    }
  },
  []);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (document.referrer.includes('cognicept.systems') && window.location.origin.includes('kabam.ai') && event.origin !== window.location.origin) {
        const tokenArr = [];
        Object.keys(event.target.localStorage).forEach((key) => {
          if (key.includes('/push')) {
            const token = JSON.parse(event.target.localStorage[key]).device_token;
            tokenArr.push(token);
          }
        });

        tokenArr.forEach((token) => {
          messaging.deleteToken(token).then(() => {
            console.log('Token deleted');
          });
        });
      }
    });
  });

  // looks for deviceInfo and update settings state
  useEffect(() => {
    const devInfo = localStorage.getItem(`${userDetails.userId}/push`);
    if (devInfo) {
      const parseInfo = JSON.parse(devInfo);
      dispatch(setDevice(parseInfo));
      dispatch(getDeviceSettings({ device_id: parseInfo.device_id }));
    }
  }, [dispatch, userDetails]);

  const handleLoginFCM = useCallback(async () => {
    const deviceInfo = localStorage.getItem(`${userDetails.userId}/push`);
    if (deviceInfo) {
      const parsedInfo = JSON.parse(deviceInfo);
      const res = await activateDevice({ device_id: parsedInfo.device_id });
      if (res?.status === 200) {
        localStorage.setItem(`${userDetails.userId}/push`,
          JSON.stringify({
            ...parsedInfo
          }));
      } else {
        console.error('Invalid status codes', res, res.status);
      }
    }
  }, [userDetails.userId]);

  // when user logs in activate push in backend
  useEffect(() => {
    if (userLoginStatus === LOGGED_IN) {
      handleLoginFCM();
    }
  }, [handleLoginFCM, userLoginStatus]);

  // request for notification permission if not already granted by the user.
  // TODO: remove the setTimeout and check for the userLoginStatus
  useEffect(() => {
    const notficationTimer = setTimeout(() => {
      if (userLoginStatus === LOGGED_IN && process.env.NODE_ENV === 'production') {
        if (!('Notification' in window)) {
          console.error('Notifications not supported in this browser');
        } else {
          if (Notification.permission === 'default') {
            requestNotificationPermission().then(() => {
              getToken();
            }).catch((err) => {
              console.error('Permission denied', err);
            });
          } else if (Notification.permission === 'granted') {
            // if from outside the app user granted permission from os
            const deviceInfo = localStorage.getItem(`${userDetails.userId}/push`);
            if (deviceInfo === null && userDetails.userId) {
              getToken();
            }
          }
        }
      }
    }, 2000);

    return () => {
      clearTimeout(notficationTimer);
    };
  }, [dispatch, userDetails.userId, userLoginStatus]);

  // Check for notification permission and request if needed
  function requestNotificationPermission() {
    return new Promise((resolve, reject) => {
      if (!('Notification' in window)) {
        reject(new Error('Notifications not supported in this browser'));
      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            resolve();
          } else {
            reject(new Error('Permission denied'));
          }
        });
      }
    });
  }
  // You can now use this token to send push notifications

  const skipWaiting = async(registration) => {
    try {
      if (refreshToken) {
        dispatch(resetAccessToken(refreshToken));
        const response = await http.post(`user/refresh`);
        if (response?.status === 200 && response?.data?.access_token) {
          dispatch(resetAccessToken(response?.data?.access_token));
        } else {
          dispatch(logout());
        }
      }
    } catch (err) {
      dispatch(logout());
    }
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  };
  // reload current route
  const reloadPage = () => {
    window.location.reload();
  };
  // open location related to the notification
  const routeNotificationUrl = (event) => {
    if (event.data?.messageType === 'notification-clicked') {
      window.location.href = event.data?.notification?.click_action;
    }
  };

  useEffect(() => {
    const setoffline = () => dispatch(setOffline(true));
    window.addEventListener('offline', setoffline);
    navigator.serviceWorker.addEventListener('controllerchange', reloadPage);
    navigator.serviceWorker.addEventListener('message', routeNotificationUrl);
    return () => {
      window.removeEventListener('offline', setoffline);
      navigator.serviceWorker.removeEventListener('controllerchange', reloadPage);
      navigator.serviceWorker.removeEventListener('message', routeNotificationUrl);
    };
  }, [dispatch]);

  const onSWUpdate = (registration) => {
    dispatch(enqueueSnackbar({
      message: 'A new version is available!',
      isClearable: false,
      variant: 'success',
      key: new Date().getTime() + Math.random(),
      actions: { Reload: () => skipWaiting(registration) },
      options: {
        autoHideDuration: null
      }
    }));
  };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate
    });
  }, []);
  return (
    <>
      {url && <iframe src={`${url}unregister`} title="Unregister" id="iframe-unregister" style={{ display: 'none', position: 'absolute' }} />}
      {children}
    </>
  );
};

export default Index;
