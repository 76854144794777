/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import snackbar from 'snackbar/snackbar';
import PrivateRoute from 'private-route/PrivateRoute';
import CircularLoader from 'components/Loaders/CircularLoader';
import Page from 'components/Page';
import { selectUserDetails } from 'pages/auth/authSlice';
import { selectOrganizations } from 'layouts/header/organization/organizationsSlice';
import { selectLoading, selectProgress } from 'components/Loaders/loadingSlice';
import Permission from 'features/Permission';
import GlobalLoader from 'components/Loaders/GlobalLoader';
import OfflineFallback from 'components/OfflineFallback';
import ErrorBoundary from 'components/ErrorBoundary';
import { useTheme } from '@mui/material/styles';
import InvitationExpired from 'pages/auth/invitation-expired/ExpiredInvitation';

const Login = React.lazy(() => import('pages/auth/Signin/Login'));
const Logout = React.lazy(() => import('pages/auth/Signout/Logout'));
const Verify = React.lazy(() => import('pages/auth/Signin/Verify'));
const ForgetPassword = React.lazy(() => import('pages/auth/Signin/ForgetPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/Signin/ResetPassword'));
const Signup = React.lazy(() => import('pages/auth/Signup'));
const Invite = React.lazy(() => import('pages/auth/Invite'));
const EmailVerification = React.lazy(() => import('pages/auth/EmailVerification/EmailVerification'));
const EmailVerified = React.lazy(() => import('pages/auth/EmailVerification/EmailVerified'));
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const Robotops = React.lazy(() => import('pages/robotops'));
const Tickets = React.lazy(() => import('pages/tickets'));
const Ticket = React.lazy(() => import('pages/ticket'));
const Alerts = React.lazy(() => import('pages/alerts'));
const Alert = React.lazy(() => import('pages/alert'));
const EventHistory = React.lazy(() => import('pages/ticket/EventHistory'));
const FMImageAnnotator = React.lazy(() => import('pages/ticket/FMImageAnnotator'));
const TicketIndex = React.lazy(() => import('pages/ticket/TicketIndex'));
const Comments = React.lazy(() => import('pages/ticket/Comments'));
const RobotPage = React.lazy(() => import('pages/robotpage'));
const PilotCameraPopout = React.lazy(() => import('pages/robotpage/components/pilot/pilotcamera/PilotCameraPopout'));
const Overview = React.lazy(() => import('pages/robotpage/components/overview'));
const EventLog = React.lazy(() => import('pages/robotpage/components/eventlog'));
const Pilot = React.lazy(() => import('pages/robotpage/components/pilot'));
const PilotOnsite = React.lazy(() => import('pages/robotpage/components/pilot-onsite'));
const Terminal = React.lazy(() => import('pages/robotpage/components/terminal'));
const TopicMonitor = React.lazy(() => import('pages/robotpage/components/topic-monitor'));
const Missions = React.lazy(() => import('pages/robotpage/components/missions'));
const Config = React.lazy(() => import('pages/robotpage/components/config'));
const InstallationTab = React.lazy(() => import('pages/robotpage/components/config/components/Installation'));
const TopicConfiguratorTab = React.lazy(() => import('pages/robotpage/components/config/components/TopicConfigurator'));
const PropertiesTab = React.lazy(() => import('pages/robotpage/components/config/components/Properties/Properties'));
const MapTab = React.lazy(() => import('pages/robotpage/components/config/components/Map/Map'));
const Settings = React.lazy(() => import('pages/settings'));
const AccountsTab = React.lazy(() => import('pages/settings/components/Accounts'));
const SecurityTab = React.lazy(() => import('pages/settings/components/Security'));
const NotificationsTab = React.lazy(() => import('pages/settings/components/Notifications'));
const ThemesTab = React.lazy(() => import('pages/settings/components/Themes'));
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));
const Management = React.lazy(() => import('pages/management'));
const UsersList = React.lazy(() => import('pages/management/components/user/UsersList'));
const RobotsList = React.lazy(() => import('pages/management/components/robot/RobotsList'));
const SitesList = React.lazy(() => import('pages/management/components/site/SitesList'));
const PrivacyPolicy = React.lazy(() => import('pages/settings/PrivacyPolicy'));
const Unregister = React.lazy(() => import('pages/settings/Unregister'));
const DataExplorer = React.lazy(() => import('pages/robotpage/components/dataexplorer'));

function App() {
  const userDetails = useSelector(selectUserDetails);
  const organizations = useSelector(selectOrganizations);
  const isLoading = useSelector(selectLoading);
  const progressValue = useSelector(selectProgress);
  const [currentOrganization, setCurrentOrganization] = useState('');
  const theme = useTheme();

  // sets current organization of user, for page title
  useEffect(() => {
    if (organizations && userDetails) {
      setCurrentOrganization(
        organizations.find(
          (org) => org.organization_code === userDetails.organizationCode
        )?.organization_name
      );
    }
  }, [organizations, userDetails]);

  snackbar();
  return (
    <BrowserRouter>
      {isLoading && <GlobalLoader currentProgress={progressValue} />}
      <React.Suspense fallback={<CircularLoader />}>
        <Routes>
          <Route
            path="/invite"
            exact={true}
            element={<Page component={<Invite />} title="Invitation" />}
          />
          <Route
            path="/invite-expired"
            element={<Page component={<InvitationExpired />} title="Invitation expired" />}
          />
          <Route
            path="/email-verification"
            element={
              <Page
                component={<EmailVerification />}
                title="Verifying Email..."
              />
            }
          />
          <Route
            path="/email-verified"
            element={
              <Page component={<EmailVerified />} title="Email Verified" />
            }
          />
          <Route
            exact={true}
            path="/login"
            element={
              <Page component={<Login />} title="Login Kabam Robotics" />
            }
          />
          <Route exact={true} path="/logout" element={<Logout />} />
          <Route exact={true} path="/login/verify" element={<Verify />} />
          <Route
            exact={true}
            path="/login/forgetpassword"
            element={<ForgetPassword />}
          />
          <Route
            exact={true}
            path="/resetpassword"
            element={<ResetPassword />}
          />
          <Route
            exact={true}
            path="/signup"
            element={
              <Page component={<Signup />} title="SignUp Kabam Robotics" />
            }
          />
          <Route exact={true} element={<PrivateRoute />}>
            <Route path="/robotstream">
              <Route
                path=":robot_code"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="robotops:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotPage
                              defaultFullscreen={true}
                              defaultRobotAccess={true}
                            />
                          </React.Suspense>
                        </Permission>
                      }
                    />
                  </ErrorBoundary>
                }
              >
                <Route
                  path=":selectedScreen"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="robotops:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <PilotCameraPopout isFLip={true} />
                            </React.Suspense>
                          </Permission>
                        }
                      />
                    </ErrorBoundary>
                  }
                />
              </Route>
            </Route>
            <Route
              path="/"
              element={
                <ErrorBoundary theme={theme}>
                  <React.Suspense fallback={<CircularLoader />}>
                    <DefaultLayout />
                  </React.Suspense>
                </ErrorBoundary>
              }
            >
              <Route path=":organization_code">
                <Route
                  path="dashboard"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="dashboard:visit">
                            <OfflineFallback>
                              <React.Suspense fallback={<CircularLoader />}>
                                <Dashboard />
                              </React.Suspense>
                            </OfflineFallback>
                          </Permission>
                        }
                        title={`Dashboard - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="robotops"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="robotops:visit">
                            <OfflineFallback showMessage={true}>
                              <React.Suspense fallback={<CircularLoader />}>
                                <Robotops />
                              </React.Suspense>
                            </OfflineFallback>
                          </Permission>
                        }
                        title={`RobotOps - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                />
                <Route path="robot">
                  <Route
                    path=":robot_code"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Page
                          component={
                            <Permission permissionName="robotops:visit">
                              <React.Suspense fallback={<CircularLoader />}>
                                <RobotPage />
                              </React.Suspense>
                            </Permission>
                          }
                          title={`Robot Page - ${currentOrganization}`}
                        />
                      </ErrorBoundary>
                    }
                  >
                    <Route index={true} element={<Navigate to="overview" />} />
                    <Route
                      path="overview"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Overview />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="dataexplorer"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="data_explorer:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <DataExplorer />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="eventlog"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="event_logs:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <EventLog />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="pilot"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Pilot />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="pilot-onsite"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="pilot_onsite:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <PilotOnsite />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="topic-monitor"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="topic_monitor:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <TopicMonitor />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="terminal"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="terminal:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <Terminal />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="missions"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="missions:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <Missions />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="config"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="config:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <Config />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    >
                      <Route index={true} element={<Navigate to="installation" />} />
                      <Route
                        path="installation"
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="installation:visit">
                              <React.Suspense fallback={<CircularLoader />}>
                                <InstallationTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="topic-configurator"
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="topic_configurator:visit">
                              <React.Suspense fallback={<CircularLoader />}>
                                <TopicConfiguratorTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="map"
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="config_map:visit">
                              <React.Suspense fallback={<CircularLoader />}>
                                <MapTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path="properties"
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="config_properties:visit">
                              <React.Suspense fallback={<CircularLoader />}>
                                <PropertiesTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  path="tickets"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="tickets:visit">
                            <OfflineFallback>
                              <React.Suspense fallback={<CircularLoader />}>
                                <Tickets />
                              </React.Suspense>
                            </OfflineFallback>
                          </Permission>
                        }
                        title={`Tickets - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                >
                  <Route path=":ticket_id">
                    <Route
                      path="eventHistory"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <EventHistory />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="comments"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Comments />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="facilityManagement"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <FMImageAnnotator />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                  <Route
                    index={true}
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <TicketIndex />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                </Route>
                <Route path="ticket">
                  <Route
                    path=":ticket_id"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Page
                          component={
                            <Permission permissionName="tickets:visit">
                              <OfflineFallback>
                                <React.Suspense fallback={<CircularLoader />}>
                                  <Ticket />
                                </React.Suspense>
                              </OfflineFallback>
                            </Permission>
                          }
                          title={`Ticket Page - ${currentOrganization}`}
                        />
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      path="eventHistory"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <EventHistory />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="comments"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Comments />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="facilityManagement"
                      element={
                        <ErrorBoundary theme={theme}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <FMImageAnnotator />
                          </React.Suspense>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="alerts"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Alerts />
                            </React.Suspense>
                          </OfflineFallback>
                        }
                        title={` Alerts - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                  title={` Alerts - ${currentOrganization}`}
                />
                <Route
                  path="alert/:alert_id"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Alert />
                            </React.Suspense>
                          </OfflineFallback>
                        }
                        title={` Alert - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="management"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Management />
                            </React.Suspense>
                          </OfflineFallback>
                        }
                        title={`Management - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                >
                  <Route index={true} element={<Navigate to="sites" />} />
                  <Route
                    path="users"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="users:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <UsersList />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="robots"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="robots:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotsList />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="sites"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="sites:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <SitesList />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                </Route>
                <Route
                  path="video"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page title={`Video - ${currentOrganization}`} />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Settings />
                            </React.Suspense>
                          </OfflineFallback>
                        }
                        title={`Settings - ${currentOrganization}`}
                      />
                    </ErrorBoundary>
                  }
                >
                  <Route index={true} element={<Navigate to="account" />} />
                  <Route
                    path="account"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <AccountsTab />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="security"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <SecurityTab />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="notifications"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <NotificationsTab />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="themes"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <ThemesTab />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                </Route>
                <Route path="*" />
              </Route>
              <Route
                path="*"
                element={
                  <ErrorBoundary theme={theme}>
                    <Navigate to="/" />
                  </ErrorBoundary>
                }
              />
            </Route>
          </Route>
          <Route
            path="privacy"
            element={
              <ErrorBoundary theme={theme}>
                <Page
                  component={
                    <React.Suspense fallback={<CircularLoader />}>
                      <PrivacyPolicy />
                    </React.Suspense>
                  }
                  title="Privacy Policy"
                />
              </ErrorBoundary>
            }
          />
          <Route
            path="unregister"
            element={
              <ErrorBoundary theme={theme}>
                <Page
                  component={
                    <React.Suspense fallback={<CircularLoader />}>
                      <Unregister />
                    </React.Suspense>
                  }
                  title="Unregister"
                />
              </ErrorBoundary>
            }
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
